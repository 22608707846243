/**************** SETTINGS *****************/


$desktop-fixed-width:1000px;

$serif-primary: 'Georgia';
$serif-secondary: 'Georgia';
$sans-primary: 'Montserrat';
$sans-secondary: 'PT Sans';

$background-light:#f8f8f8;
$background-dim:#494c4f;
$background-dark:#171717;
$background-darker:#556066;

$brand-primary:#ffffff;
$brand-secondary:#686868;
$brand-primary-accent:#3f9d46;
$brand-secondary-accent:#439d44;
$brand-tertiary-accent:#3c669b;

$font-light:#fff;
$font-faded:#6a6a6a;
$font-gray:#ababab;
$font-dark:#595959 ;
$font-accent:#549ba0;
$font-accent-alt:#549ba0;

$font-line-height:1.4em;

$font-size-base:16px;

$font-largest:3.6em;
$font-larger:2.2em;
$font-large:1.60em;
$font-mid:1.5em;
$font-middle:1.35em;
$font-medium:1.1em;
$font-small:.9em;
$font-smaller:.8em;
$font-smallest:.7em;

$font-size-alt:20px;

$font-m-largest:2.8em;
$font-m-larger:1.6em;
$font-m-large:1.55em;
$font-m-mid:1.5em;
$font-m-middle:1.25em;
$font-m-medium:1em;
$font-m-small:.9em;
$font-m-smaller:.8em;
$font-m-smallest:.7em;







