/* Style adjustments for viewports that meet the condition */
@include respond-to(phone, tablet)
{

	header nav {

	}

	.js nav {
		display:block;
		position:fixed;
		right:-220px;
		top:0;
		width:220px;
		margin:0;
		z-index:9999;

		/* When there are more elements then page height, show a scroll bar */
		//overflow-y:auto;
	}

	.js body {
		transform:translate3d(0, 0, 0);
		transition:all 0.25s ease-in-out;
	}

	body.menu-in {
		transform: translate3d(-220px, 0, 0);
		overflow-x:hidden;
	}

}

