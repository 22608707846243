
// Fonts
@import url('https://fonts.googleapis.com/css?family=Montserrat:100,300,400,700');
@import url('https://fonts.googleapis.com/css?family=PT+Sans');

// Variables
@import "settings";

//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.
$grid-gutter-width:         30px !default;

//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs:                  480px;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  480px;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  480px;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  1500px;

// Small screen / tablet
$container-tablet:             (480px + $grid-gutter-width);

// Medium screen / desktop
$container-desktop:            (480px + $grid-gutter-width);

// Large screen / wide desktop
$container-large-desktop:      (1140px + $grid-gutter-width);


// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Skagga Utilities
@import "vendor/skagga/utilities";

@import "settings";
@import "vendor/skagga/utilities";
@import "components/footer";
@import "components/header";
@import "components/mobile-flyout";
@import "components/video";

body {
    font-family: $sans-primary;
    font-weight:300;
    color:$font-dark;
    font-size:$font-size-base;
    background-color:#fff;
    @include respond-to(phone) {
        font-size:$font-m-larger;
        overflow-x:hidden;
    }
    @include respond-to(desktop)
    {
        min-width:$desktop-fixed-width;
    }
}

a {
    color:$font-dark;
}

.content a.no-decor {
    text-decoration:none;
}

.content a {
    text-decoration:underline;
    color:$brand-primary-accent;
}

a.light {
    color:$font-light;
}

a:hover,
a:focus,
a:active {
    color:$brand-secondary;
    text-decoration:none;
}

a.light:hover {
    color:$font-gray;
}

h3 a {
    color:$brand-primary;
}

h3 a:hover {
    color:$font-dark;
}

p {
    line-height:$font-line-height;
    color:$brand-secondary;
}

p span {
    color:$font-light;
}

p span a {
    color:$brand-primary-accent;
}

footer p span {
    text-shadow:0 0 0;
}

p span.alt {
    color:$font-accent-alt;
    font-size:$font-size-alt;
}

.center-text {
    text-align:center;
}

.center-all {
    margin-left:auto;
    margin-right:auto;
}

.center-mobile {
    @include respond-to(phone) {
        text-align:center;
    }
}

.text-desk--right_mobile--center {
    text-align:center;
    @include respond-to(desktop) {
        text-align:right;
    }
}

.center-contents-on-mobile {
    @include respond-to(phone) {
        text-align:center;
    }
}

h1 {
    font-family:$sans-primary;
    font-size:$font-largest;
    font-weight:500;
    // text-shadow: 2px 2px 10px $brand-primary;
    @include respond-to(phone) {
            font-size:$font-m-largest;
    }
}

h1 span {
    color:$brand-secondary-accent;
}

.page-heading h1 {
    margin:50px 0;
    @include respond-to(phone) {
            margin:25px 0;
    }
}

h2 {
    font-family:$sans-primary;
    font-size:$font-larger;
    color:$brand-primary-accent;
    font-weight:300;
    padding-bottom:10px;
    display:block;
    @include respond-to(phone) {
            font-size:$font-m-larger;
    }
}

h2 span {
    line-height:10px;
}

h3 {
    font-family:$sans-primary;
    font-size:$font-large;
    font-weight:300;
    margin-bottom:0;
    @include respond-to(phone) {
            font-size:$font-m-larger;
    }
}

h3 span {
    font-size:.8em;
}

.background-news h3 {
    text-transform:none;
}

.center-heading {
    text-align:center;
}

.callout {
    display:block;
    font-family:$serif-primary;
    font-size:$font-large;
    color:$brand-secondary-accent;
    font-style:italic;
    border-bottom:1px solid $brand-tertiary-accent;
    border-top:1px solid $brand-tertiary-accent;
    margin:30px 0;
    padding:30px 0;
    @include respond-to(phone) {
            font-size:$font-m-large;
    }
}

h4 {
    font-family:$sans-primary;
    font-size:$font-middle;
    margin-top:15px;
    padding-top:5px;
    font-weight:300;
}

h4 span {
    font-size:.8em;
}

h5 {
    color:$brand-primary-accent;
    font-weight:500;
    font-family:$sans-primary;
    font-weight:$font-middle;
}

h6 {
    font-family:$serif-primary;
    font-size:$font-smaller;
    font-size:$font-dark;
    @include respond-to(phone) {
        font-size:$font-m-smaller;
    }
}

ul.content-list {
    margin:10px 0 10px 25px;
    padding: 0;
}

ul.content-list li {
    padding: 5px 0 5px 10px;
    text-transform:uppercase;
}

.bottom-border-text {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid $brand-secondary-accent;
    clear:both;
}

.page-title-alt h2 {
    color:#fff;
    text-shadow: 0px 0px 15px #000;
}

blockquote {
    font-family:$sans-primary;
    font-size:$font-medium;
    color:$font-light;
    text-align:left;
    margin-top:30px;
    padding-right:10px;
    border:none;
    @include respond-to(phone) {
        font-size:$font-m-small;
    }
}

blockquote.left-border {
    @include respond-to(desktop) {
        border-left:1px solid $brand-primary;
        padding-left:30px;
        margin-left:-30px;
    }
}

blockquote + p {
    float:right;
    margin:0 30px 30px 10px;
}

.media-side {

}

.media-side--body {
    margin:20px 0;
    @include respond-to(desktop)
    {
        margin-top:60px;
    }
}

.upper {
    text-transform:uppercase;
}

.upper-serif {
    background:transparent url('../img/line-accents.png') 50% 50% no-repeat;
    text-transform:uppercase;
    font-family:$serif-primary;
    font-size:$font-medium;
    margin:15px 0;
    letter-spacing:2px;
    @include respond-to(phone) {
            font-size:$font-m-smaller;
    }
}

#main {
    //background-color:$background-light;
}

.container {
    width:100%;
    @include respond-to(desktop)
    {
        width:$desktop-fixed-width;
    }
}

.background-home .content-light-bg {
    margin:0;
    padding-bottom:50px;
}

.meet-the-team-bg {
    background:$background-light;
}

.content-light-bg {
    background:$background-light;
    min-height:20px;
    margin:30px 0;
    padding:30px 0 0 0;
    width:100%;
    @include respond-to(desktop)
    {
        margin:50px 0;
        padding:50px 0 0 0;
    }
}

.content-dark-bg {
    background:$background-dark;
    min-height:20px;
    margin:30px 0;
    padding:30px 0 50px 0;
    width:100%;
    @include respond-to(desktop)
    {
        padding:50px 0;
    }
}

.content-testimonial-bg {
    background:$background-light url('../img/page-bg/testimonial-bg.gif') no-repeat;
    min-height:20px;
    padding:70px 0 70px 0;
    width:100%;
    background-size:cover;
    @include respond-to(desktop)
    {
        padding:70px 0;
    }
}

.content-speck-bg {
    background:$background-light url('../img/page-bg/team-bg.gif') no-repeat;
    min-height:20px;
    margin:30px 0 30px 0;
    padding:50px 0 50px 0;
    width:100%;
    background-size:cover;
    @include respond-to(desktop)
    {
        padding:70px 0;
    }
}

.content-speck-bg.page-bottom {
    margin:30px 0 -50px 0;
}

.background-testimonials .content-stripe-bg,
.background-testimonials .content-dim-bg,
.background-testimonials .content-light-bg {
    padding:40px 0;
    text-align:left;
    margin:0;
    //border-top:1px solid #d9d9d9;
    //border-bottom:1px solid #d9d9d9;
}


.content-dim-bg {
    background:$background-dim;
    min-height:20px;
    margin:0;
    padding:70px 0;
    @include respond-to(desktop)
    {

    }
}

.spacer {
    display:block;
    height:80px;
    @include respond-to(site-max)
    {
        height:120px;
    }
    @include respond-to(phone)
    {
        height:0;
    }
}

span.spacer {
    display:block;
    height:40px;
    @include respond-to(phone)
    {
        height:0;
    }
}

.copy-wrap {
    margin-top:40px;
    margin-bottom:40px;
    @include respond-to(desktop)
    {
        margin-top:50px;
        margin-bottom:50px;
    }
}

.copy-wrap-top {
    margin-top:20px;
    @include respond-to(desktop)
    {
        margin-top:40px;
    }
}

.copy-wrap-top-desk {
    @include respond-to(desktop)
    {
        margin-top:30px;
    }
}

.copy-wrap-top-max {
    margin-top:20px;
    @include respond-to(desktop)
    {
        margin-top:120px;
    }
}

.copy-wrap-bot {
    margin-bottom:20px;
    @include respond-to(desktop)
    {
        margin-bottom:40px;
    }
}

.copy-wrap-more {
    margin-top:30px;
    margin-bottom:30px;
    @include respond-to(desktop)
    {
        margin-top:80px;
        margin-bottom:80px;
    }
}

.copy-wrap-alt {
    margin-top:10px;
    margin-bottom:10px;
    @include respond-to(desktop)
    {
        margin-top:20px;
        margin-bottom:20px;
    }
}

.featured-buttons {
    @include respond-to(desktop)
    {
        width:550px;
    }
    margin:0 auto;
    padding:50px 0;
    text-transform:uppercase;
}

.featured-buttons li {
    @include respond-to(desktop)
    {
        display:inline;
        float:left;
    }
    @include respond-to(phone)
    {
        text-align:center;
        margin:-16px 10px;
    }
    list-style-type:none;
}

a.button.first {
    @include respond-to(desktop)
    {
        border-top-left-radius:7px;
        border-bottom-left-radius:7px;
    }
    @include respond-to(phone)
    {
        border-top-left-radius:7px;
        border-top-right-radius:7px;
    }
}

a.button.last {
    @include respond-to(desktop)
    {
        border-top-right-radius:7px;
        border-bottom-right-radius:7px;
    }
    @include respond-to(phone)
    {
        border-bottom-right-radius:7px;
        border-bottom-left-radius:7px;
    }
}

a.button {
    color:$font-light;
    background-color:$brand-secondary-accent;
    border:1px solid $brand-secondary-accent;
    line-height:25px;
    padding:7px 20px;
    margin:30px 0;
    text-decoration:none;
    white-space:nowrap;
    font-size:$font-medium;
    text-transform:uppercase;
}

a.button:hover {
    background-color:transparent;
    _box-shadow:1px 1px 1px #67D395;
    border-color:$brand-secondary-accent;
    text-decoration:none;
    color:$brand-secondary-accent;
}

a.button:active {
    background:$font-gray;
    color:$font-light;
    text-shadow:none;
    border-color:$font-gray;
}

.tagline-rotator {
    background:transparent url('../img/p61-logo-tag.svg') 50% 120% no-repeat;
    background-size:160px;
    height:600px;
    padding-top:50px;
}

.subpage-hero {
    background-size:160px;
    @include respond-to(desktop) {
        height:595px;
        overflow:hidden;
        padding-top:80px;
    }
}

.testimonial p {
    font-style:italic;
    color:$font-faded;
}

.testimonial h4 {
    text-align:right;
}

.copy-wrap img.left {
    @include respond-to(desktop)
    {
        margin:0 10px 10px 0;
        float:left;
    }
    @include respond-to(phone)
    {
        display:block;
        margin:0 auto 20px auto;
    }
}

.copy-wrap img.right {
    @include respond-to(desktop)
    {
        margin:0 0 10px 10px;
        float:right;
    }
    @include respond-to(phone)
    {
        display:block;
        margin:0 auto 20px auto;
    }
}

.img-slide img {
    @include respond-to(desktop) {
        width:800px;
        margin:0 auto -75px auto;
    }
    @include respond-to(phone) {
        box-shadow:none;
        width:100%;
    }
    text-align:center;
    border:1px solid #d9d9d9;
    border-radius:5px;
}

.img-slide-villa img {
    @include respond-to(desktop) {
        width:500px;
        margin:0 auto;
    }
}

.js-slideshow {
    @include respond-to(desktop) {
        width:90%;
        margin:0 auto;
    }
}

.js-slideshow-nav {
    margin:0 50px;
}

.js-slideshow-nav img {
    border-radius:3px;
}

.img-slide p span {
    text-align:center;
    margin-top:-35px;
    color:$font-light;
    display:block;
    text-shadow:1px 1px 4px #000;
    background-color:#fff;
}

.js-slick-prev-arrow {
    display:block;
    height:90px;
    width:20px;
    float:left;
    background:transparent url('../img/prev-arrow.png') no-repeat;
    background-size:contain;
    margin:0 15px 0 15px;
    cursor:pointer;
    z-index:99;
}

.js-slick-next-arrow {
    display:block;
    height:90px;
    width:20px;
    float:right;
    background:transparent url('../img/next-arrow.png') no-repeat;
    background-size:contain;
    margin:0 10px 0 15px;
    cursor:pointer;
    z-index:99;
}

.quadfold div {
    @include respond-to(desktop) {
        margin:20px 0;
        padding:0 20px;
    }
}

.quadfold h5 {
    min-height:40px;
}

.bg-opt {
    background:rgba(255, 255, 255, 0.6);
}

.bg-alt {
    background:rgba(230, 230, 230, 0.5);
}

.container-slim {
    @include respond-to(phone) {
        margin:0;
        padding:0;
    }
}

.mobile-vanish {
    @include respond-to(phone) {
      position: absolute;
      top: -9999px;
      left: -9999px;
      overflow:hidden;
    }
}

.desk-vanish {
    @include respond-to(desktop) {
      position: absolute;
      top: -9999px;
      left: -9999px;
      overflow:hidden;
    }
}

.wide-vanish {
    @include respond-to(site-max) {
      position: absolute;
      top: -9999px;
      left: -9999px;
      overflow:hidden;
    }
}

.news--archive-post li {
    margin:0 0 10px 20px;
}

.article {
    margin-bottom:30px;
    border-bottom:1px solid #d9d9d9;
}

.thin {
    font-weight:100;
}

.light-weight {
    font-weight:300;
}

.thick {
    font-weight:500;
}

.accent {
    color:$brand-primary-accent;
}

.primary {
    color:$brand-primary;
}

.secondary {
    color:$brand-secondary;
}

.caps {
    text-transform:uppercase;
}

.mobile-img {
    text-align:center;
    width:100%;
}

.octicon--large {
    font-size:60px !important;
    margin-bottom:10px;
}

.octicon--larger {
    font-size:100px !important;
}

.shelf {
    margin-bottom:0;
    padding-bottom:0;
}

.stack,
.tuck {
    margin-top:0;
    padding-top:0;
}

.desk-collapse {
    @include respond-to(desktop) {
        padding:1px;
        margin:0;
    }
}

.phone-collapse {
    @include respond-to(phone) {
        padding:1px;
        margin:0;
    }
}

.text-block-outside-container {
    margin:7.5px;
    padding:7.5px;
    @include respond-to(desktop) {
        margin:0 20px;
        padding:0 20px;
    }
}

.testimonal-author {
    padding-top:30px;
    text-align:right;
    font-weight:100;
    text-transform:uppercase;
}

.section-liner {
    border-top:1px dotted #d9d9d9;
    padding-top:50px;
}

.section-liner--slim {
    border-top:1px dotted #d9d9d9;
    padding-top:20px;
}

.line-accents {
    background:transparent url('../img/line-accents.png') 50% 50% no-repeat;
    display:block;
    color:$brand-primary-accent;
    font-weight:500;
    font-family:$sans-primary;
    font-weight:$font-middle;
}

.line-accents--white {
    background:transparent url('../img/line-accents--white.png') 50% 50% no-repeat;
    display:block;
}

.btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 2px 6px;
    font-size: $font-medium;
    line-height: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius:0;
    text-transform:uppercase;
    text-decoration:none !important;
}

.btn-primary {
    color: $brand-secondary-accent;
    background-color: transparent;
    border-color: $brand-secondary-accent;
    text-decoration:none;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
    color: $brand-primary-accent;
    background-color: transparent;
    border-color: $brand-primary-accent;
    text-decoration:none;
}

.word-case {
    text-transform:none;
}

.client-copy-block {
    margin-top:50px;
}

.large-icon {
    font-size:50px !important;
    color:$brand-secondary;
}

.icon-label {
    font-size:$font-smaller;
    line-height:5px;
}

.mls-app-diagram {
    background:url(../img/mls-app-diagram.svg) 50px 20% no-repeat;
    min-height:885px;
    background-size:120%;
}

.gray-arrow {
    display:block;
    background:url(../img/gray-arrow.svg) bottom center no-repeat;
    background-size:15%;
    min-height:310px;
}

.green-arrow {
    display:block;
    background:url(../img/green-arrow.svg) bottom center no-repeat;
    background-size:15%;
    min-height:370px;
}

.pull-up {
    margin-top:-120px;
}

@import "components/slick";
@import "components/slick-theme";
@import "fonts/socialicons";





