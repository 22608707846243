header {
	display:block;
	min-height:220px;
}


.background-mls header {
	background-image:url(../img/skagga-green.jpg);
	background-position:0 0;
	background-repeat:no-repeat;
	background-size: cover;
	min-height:600px;
}

.mobile-button {
	@include respond-to(desktop)
	{
		display:none;
	}

}

.hamburger-label {
	font-size:$font-m-medium;
	font-weight:100;
	color:$font-light;
	position:fixed;
	top:20px;
	right:15px;
	font-family:$sans-primary;
	border:1px solid $font-light;
	padding:1px 5px;
	border-radius:5px;
	text-transform:uppercase;
}

.hamburger-label:hover,
.hamburger-label:active,
.hamburger-label:focus {
	background:$brand-primary-accent;
	color:$font-light;
}

nav {
	@include respond-to(phone)
	{
		border-left:1px solid #d9d9d9;
		height:100%;
		background:transparent;
	}
}

.nav-block {
	background:transparent;
	padding:10px 0;
	height:70px;
}

.nav-block__wrap {
	display:none;
	margin:10px 0 0 0;
	//background-color: transparent;

	@include respond-to(desktop)
	{
		display:block;
		text-align:right;
		margin-right:10px;
		//border:none;
	}
}

.nav > li {
	font-family:$sans-primary;
	line-height:14px;
	font-weight:300;

	@include respond-to(phone)
	{
		margin:0;
		font-size:$font-medium;
	}

	@include respond-to(desktop)
	{
		display:inline-block;
		padding:0 6px 0 0;
		font-size:$font-medium;
		height:auto;
	}
}


.nav > li + li {

	@include respond-to(desktop)
	{
		// border-left:1px solid $font-faded;
		padding-left:9px;
	}
}

.nav > li > a {
	padding:0;
	text-decoration:none;
	@include respond-to(phone)
	{
		border-bottom:1px solid #d9d9d9;
	}
	// text-transform:uppercase;
}

.nav li a:hover {
	background:none;
	@include respond-to(desktop)
	{
		text-decoration:underline;
	}
}

.nav li a.symbol:hover {
	text-decoration:none;
}

.nav > li > .nav_link {

	color:$font-light;

	@include respond-to(phone)
	{
		color:$font-dark;
		padding:1em;
		display:block;
	}
}

.nav > li > .nav_link span {
	font-size:$font-m-medium;
	letter-spacing:1px;
	line-height: 30px;
	font-family:$sans-primary;
}

.nav > li > .nav_link.current {
	color:$brand-secondary-accent;
}

.nav > li > .nav_link.symbol {
	font-size:$font-m-medium;
}

.nav-phone {
	font-size:$font-m-medium;
	@include respond-to(desktop)
	{
		font-size:$font-medium;
	}
}

a.nav-block_logo {
	display:block;
	background:transparent url(../img/skagga-real-estate-logo-white.svg) 0px 15px no-repeat;
	background-size:contain;
	width:250px;
	height:80px;
	float:left;
	margin-top:-10px;
	@include respond-to(phone)
	{
		width:155px;
		margin-top:-7px;
	}
}


//NAV DROPDOWN MENU

.second-nav {
	list-style-type:none;
	margin:15px 0 -10px 0;
	@include respond-to(desktop)
	{
		display:none;
	}
}

.dropdown {
	color:$font-faded;
	@include respond-to(phone)
	{
		display:none;
	}
}

.dropdown-menu {
	@include respond-to(phone)
	{
		margin-left:40px;
		margin-bottom: 15px;
		font-size:smaller;
		display:none;
		float:none;
	}

	@include respond-to(desktop)
	{
		z-index: 1; /* Make this appear over other elements */
		padding:0;
		position:absolute;
		width:150px;
		display:none;
	}
}

.dropdown-menu > li {
	@include respond-to(desktop)
	{
	  display:block;
	  padding:0 10px;
	}
}

.dropdown-menu > li + li, .second-nav > li + li {
	border-top: 1px solid #e6e7e8;
}

.dropdown-menu > li > a {
  display:block;
  padding:7px 10px;
  color:$font-faded;
}

.dropdown-menu > li > a:hover {
	@include respond-to(desktop) {
		text-decoration:none;
		color:$font-dark;
	}
}

.dropdown-toggle:hover + .dropdown-menu,
.dropdown:hover > .dropdown-menu {
	@include respond-to(desktop)
	{
		display:block;
		margin-top:3px;
		padding:10px 0;
	}
}



