/**
 * MONO SOCIAL ICONS FONT
 * http://drinchev.github.io/monosocialiconsfont/
 * Version 1.10
 */

@font-face {
    font-family: 'Mono Social Icons Font';
    src: url('../fonts/MonoSocialIconsFont-1.10.eot');
    src: url('../fonts/MonoSocialIconsFont-1.10.eot?#iefix') format('embedded-opentype'),
         url('../fonts/MonoSocialIconsFont-1.10.woff') format('woff'),
         url('../fonts/MonoSocialIconsFont-1.10.ttf') format('truetype'),
         url('../fonts/MonoSocialIconsFont-1.10.svg#MonoSocialIconsFont') format('svg');
    src: url('../fonts/MonoSocialIconsFont-1.10.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.symbol, a.symbol:before {
    font-family: 'Mono Social Icons Font';
    -webkit-text-rendering: optimizeLegibility;
    -moz-text-rendering: optimizeLegibility;
    -ms-text-rendering: optimizeLegibility;
    -o-text-rendering: optimizeLegibility;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -ms-font-smoothing: antialiased;
    -o-font-smoothing: antialiased;
    font-smoothing: antialiased;
    font-size:30px;
}

a.symbol:before {
    content: attr(title);
    margin-right: 0.3em;
    font-size: 130%;
}

a.symbol:hover {
	text-decoration:none;
}

/* --- END ---


-- three styles --
1. roundedfivehundredpx
2. circlefivehundredpx
3. fivehundredpx

---icon codes ---------
fivehundredpx	&#xe400;
aboutme			&#xe401;
addme			&#xe402;
amazon			&#xe403;
aol				&#xe404;
appstorealt		&#xe405;
appstore		&#xe406;
apple			&#xe407;
bebo			&#xe408;
behance			&#xe409;
bing			&#xe410;
blip			&#xe411;
blogger			&#xe412;
coroflot		&#xe413;
daytum			&#xe414;
delicious		&#xe415;
designbump		&#xe416;
designfloat		&#xe417;
deviantart		&#xe418;
diggalt			&#xe419;
digg			&#xe420;
dribble			&#xe421;
drupal			&#xe422;
ebay			&#xe423;
email			&#xe424;
emberapp		&#xe425;
etsy			&#xe426;
facebook		&#xe427;
feedburner		&#xe428;
flickr			&#xe429;
foodspotting	&#xe430;
forrst			&#xe431;
foursquare		&#xe432;
friendsfeed		&#xe433;
friendstar		&#xe434;
gdgt			&#xe435;
github			&#xe436;
githubalt		&#xe437;
googlebuzz		&#xe438;
googleplus		&#xe439;
googletalk		&#xe440;
gowallapin		&#xe441;
gowalla			&#xe442;
grooveshark		&#xe443;
heart			&#xe444;
hyves			&#xe445;
icondock		&#xe446;
icq				&#xe447;
identica		&#xe448;
imessage		&#xe449;
itunes			&#xe450;
lastfm			&#xe451;
linkedin		&#xe452;
meetup			&#xe453;
metacafe		&#xe454;
mixx			&#xe455;
mobileme		&#xe456;
mrwong			&#xe457;
msn				&#xe458;
myspace			&#xe459;
newsvine		&#xe460;
paypal			&#xe461;
photobucket		&#xe462;
picasa			&#xe463;
pinterest		&#xe464;
podcast			&#xe465;
posterous		&#xe466;
qik				&#xe467;
quora			&#xe468;
reddit			&#xe469;
retweet			&#xe470;
rss				&#xe471;
scribd			&#xe472;
sharethis		&#xe473;
skype			&#xe474;
slashdot		&#xe475;
slideshare		&#xe476;
smugmug			&#xe477;
soundcloud		&#xe478;
spotify			&#xe479;
squidoo			&#xe480;
stackoverflow	&#xe481;
star			&#xe482;
stumbleupon		&#xe483;
technorati		&#xe484;
tumblr			&#xe485;
twitterbird		&#xe486;
twitter			&#xe487;
viddler			&#xe488;
vimeo			&#xe489;
virb			&#xe490;
www				&#xe491;
wikipedia		&#xe492;
windows			&#xe493;
wordpress		&#xe494;
xing			&#xe495;
yahoobuzz		&#xe496;
yahoo			&#xe497;
yelp			&#xe498;
youtube			&#xe499;
instagram		&#xe500;

circlefivehundredpxcirclefivehundredpx&#xe200;
circleaboutmecircleaboutme&#xe201;
circleaddmecircleaddme&#xe202;
circleamazoncircleamazon&#xe203;
circleaolcircleaol&#xe204;
circleappstorealtcircleappstorealt&#xe205;
circleappstorecircleappstore&#xe206;
circleapplecircleapple&#xe207;
circlebebocirclebebo&#xe208;
circlebehancecirclebehance&#xe209;
circlebingcirclebing&#xe210;
circleblipcircleblip&#xe211;
circlebloggercircleblogger&#xe212;
circlecoroflotcirclecoroflot&#xe213;
circledaytumcircledaytum&#xe214;
circledeliciouscircledelicious&#xe215;
circledesignbumpcircledesignbump&#xe216;
circledesignfloatcircledesignfloat&#xe217;
circledeviantartcircledeviantart&#xe218;
circlediggaltcirclediggalt&#xe219;
circlediggcircledigg&#xe220;
circledribblecircledribble&#xe221;
circledrupalcircledrupal&#xe222;
circleebaycircleebay&#xe223;
circleemailcircleemail&#xe224;
circleemberappcircleemberapp&#xe225;
circleetsycircleetsy&#xe226;
circlefacebookcirclefacebook&#xe227;
circlefeedburnercirclefeedburner&#xe228;
circleflickrcircleflickr&#xe229;
circlefoodspottingcirclefoodspotting&#xe230;
circleforrstcircleforrst&#xe231;
circlefoursquarecirclefoursquare&#xe232;
circlefriendsfeedcirclefriendsfeed&#xe233;
circlefriendstarcirclefriendstar&#xe234;
circlegdgtcirclegdgt&#xe235;
circlegithubcirclegithub&#xe236;
circlegithubaltcirclegithubalt&#xe237;
circlegooglebuzzcirclegooglebuzz&#xe238;
circlegooglepluscirclegoogleplus&#xe239;
circlegoogletalkcirclegoogletalk&#xe240;
circlegowallapincirclegowallapin&#xe241;
circlegowallacirclegowalla&#xe242;
circlegroovesharkcirclegrooveshark&#xe243;
circleheartcircleheart&#xe244;
circlehyvescirclehyves&#xe245;
circleicondockcircleicondock&#xe246;
circleicqcircleicq&#xe247;
circleidenticacircleidentica&#xe248;
circleimessagecircleimessage&#xe249;
circleitunescircleitunes&#xe250;
circlelastfmcirclelastfm&#xe251;
circlelinkedincirclelinkedin&#xe252;
circlemeetupcirclemeetup&#xe253;
circlemetacafecirclemetacafe&#xe254;
circlemixxcirclemixx&#xe255;
circlemobilemecirclemobileme&#xe256;
circlemrwongcirclemrwong&#xe257;
circlemsncirclemsn&#xe258;
circlemyspacecirclemyspace&#xe259;
circlenewsvinecirclenewsvine&#xe260;
circlepaypalcirclepaypal&#xe261;
circlephotobucketcirclephotobucket&#xe262;
circlepicasacirclepicasa&#xe263;
circlepinterestcirclepinterest&#xe264;
circlepodcastcirclepodcast&#xe265;
circleposterouscircleposterous&#xe266;
circleqikcircleqik&#xe267;
circlequoracirclequora&#xe268;
circleredditcirclereddit&#xe269;
circleretweetcircleretweet&#xe270;
circlersscirclerss&#xe271;
circlescribdcirclescribd&#xe272;
circlesharethiscirclesharethis&#xe273;
circleskypecircleskype&#xe274;
circleslashdotcircleslashdot&#xe275;
circleslidesharecircleslideshare&#xe276;
circlesmugmugcirclesmugmug&#xe277;
circlesoundcloudcirclesoundcloud&#xe278;
circlespotifycirclespotify&#xe279;
circlesquidoocirclesquidoo&#xe280;
circlestackoverflowcirclestackoverflow&#xe281;
circlestarcirclestar&#xe282;
circlestumbleuponcirclestumbleupon&#xe283;
circletechnoraticircletechnorati&#xe284;
circletumblrcircletumblr&#xe285;
circletwitterbirdcircletwitterbird&#xe286;
circletwittercircletwitter&#xe287;
circleviddlercircleviddler&#xe288;
circlevimeocirclevimeo&#xe289;
circlevirbcirclevirb&#xe290;
circlewwwcirclewww&#xe291;
circlewikipediacirclewikipedia&#xe292;
circlewindowscirclewindows&#xe293;
circlewordpresscirclewordpress&#xe294;
circlexingcirclexing&#xe295;
circleyahoobuzzcircleyahoobuzz&#xe296;
circleyahoocircleyahoo&#xe297;
circleyelpcircleyelp&#xe298;
circleyoutubecircleyoutube&#xe299;
circleinstagramcircleinstagram&#xe300;


roundedfivehundredpxroundedfivehundredpx&#xe400;
roundedaboutmeroundedaboutme&#xe401;
roundedaddmeroundedaddme&#xe402;
roundedamazonroundedamazon&#xe403;
roundedaolroundedaol&#xe404;
roundedappstorealtroundedappstorealt&#xe405;
roundedappstoreroundedappstore&#xe406;
roundedappleroundedapple&#xe407;
roundedbeboroundedbebo&#xe408;
roundedbehanceroundedbehance&#xe409;
roundedbingroundedbing&#xe410;
roundedbliproundedblip&#xe411;
roundedbloggerroundedblogger&#xe412;
roundedcoroflotroundedcoroflot&#xe413;
roundeddaytumroundeddaytum&#xe414;
roundeddeliciousroundeddelicious&#xe415;
roundeddesignbumproundeddesignbump&#xe416;
roundeddesignfloatroundeddesignfloat&#xe417;
roundeddeviantartroundeddeviantart&#xe418;
roundeddiggaltroundeddiggalt&#xe419;
roundeddiggroundeddigg&#xe420;
roundeddribbleroundeddribble&#xe421;
roundeddrupalroundeddrupal&#xe422;
roundedebayroundedebay&#xe423;
roundedemailroundedemail&#xe424;
roundedemberapproundedemberapp&#xe425;
roundedetsyroundedetsy&#xe426;
roundedfacebookroundedfacebook&#xe427;
roundedfeedburnerroundedfeedburner&#xe428;
roundedflickrroundedflickr&#xe429;
roundedfoodspottingroundedfoodspotting&#xe430;
roundedforrstroundedforrst&#xe431;
roundedfoursquareroundedfoursquare&#xe432;
roundedfriendsfeedroundedfriendsfeed&#xe433;
roundedfriendstarroundedfriendstar&#xe434;
roundedgdgtroundedgdgt&#xe435;
roundedgithubroundedgithub&#xe436;
roundedgithubaltroundedgithubalt&#xe437;
roundedgooglebuzzroundedgooglebuzz&#xe438;
roundedgoogleplusroundedgoogleplus&#xe439;
roundedgoogletalkroundedgoogletalk&#xe440;
roundedgowallapinroundedgowallapin&#xe441;
roundedgowallaroundedgowalla&#xe442;
roundedgroovesharkroundedgrooveshark&#xe443;
roundedheartroundedheart&#xe444;
roundedhyvesroundedhyves&#xe445;
roundedicondockroundedicondock&#xe446;
roundedicqroundedicq&#xe447;
roundedidenticaroundedidentica&#xe448;
roundedimessageroundedimessage&#xe449;
roundeditunesroundeditunes&#xe450;
roundedlastfmroundedlastfm&#xe451;
roundedlinkedinroundedlinkedin&#xe452;
roundedmeetuproundedmeetup&#xe453;
roundedmetacaferoundedmetacafe&#xe454;
roundedmixxroundedmixx&#xe455;
roundedmobilemeroundedmobileme&#xe456;
roundedmrwongroundedmrwong&#xe457;
roundedmsnroundedmsn&#xe458;
roundedmyspaceroundedmyspace&#xe459;
roundednewsvineroundednewsvine&#xe460;
roundedpaypalroundedpaypal&#xe461;
roundedphotobucketroundedphotobucket&#xe462;
roundedpicasaroundedpicasa&#xe463;
roundedpinterestroundedpinterest&#xe464;
roundedpodcastroundedpodcast&#xe465;
roundedposterousroundedposterous&#xe466;
roundedqikroundedqik&#xe467;
roundedquoraroundedquora&#xe468;
roundedredditroundedreddit&#xe469;
roundedretweetroundedretweet&#xe470;
roundedrssroundedrss&#xe471;
roundedscribdroundedscribd&#xe472;
roundedsharethisroundedsharethis&#xe473;
roundedskyperoundedskype&#xe474;
roundedslashdotroundedslashdot&#xe475;
roundedslideshareroundedslideshare&#xe476;
roundedsmugmugroundedsmugmug&#xe477;
roundedsoundcloudroundedsoundcloud&#xe478;
roundedspotifyroundedspotify&#xe479;
roundedsquidooroundedsquidoo&#xe480;
roundedstackoverflowroundedstackoverflow&#xe481;
roundedstarroundedstar&#xe482;
roundedstumbleuponroundedstumbleupon&#xe483;
roundedtechnoratiroundedtechnorati&#xe484;
roundedtumblrroundedtumblr&#xe485;
roundedtwitterbirdroundedtwitterbird&#xe486;
roundedtwitterroundedtwitter&#xe487;
roundedviddlerroundedviddler&#xe488;
roundedvimeoroundedvimeo&#xe489;
roundedvirbroundedvirb&#xe490;
roundedwwwroundedwww&#xe491;
roundedwikipediaroundedwikipedia&#xe492;
roundedwindowsroundedwindows&#xe493;
roundedwordpressroundedwordpress&#xe494;
roundedxingroundedxing&#xe495;
roundedyahoobuzzroundedyahoobuzz&#xe496;
roundedyahooroundedyahoo&#xe497;
roundedyelproundedyelp&#xe498;
roundedyoutuberoundedyoutube&#xe499;
roundedinstagramroundedinstagram&#xe500;

fivehundredpxfivehundredpx&#xe000;
aboutmeaboutme&#xe001;
addmeaddme&#xe002;
amazonamazon&#xe003;
aolaol&#xe004;
appstorealtappstorealt&#xe005;
appstoreappstore&#xe006;
appleapple&#xe007;
bebobebo&#xe008;
behancebehance&#xe009;
bingbing&#xe010;
blipblip&#xe011;
bloggerblogger&#xe012;
coroflotcoroflot&#xe013;
daytumdaytum&#xe014;
deliciousdelicious&#xe015;
designbumpdesignbump&#xe016;
designfloatdesignfloat&#xe017;
deviantartdeviantart&#xe018;
diggaltdiggalt&#xe019;
diggdigg&#xe020;
dribbledribble&#xe021;
drupaldrupal&#xe022;
ebayebay&#xe023;
emailemail&#xe024;
emberappemberapp&#xe025;
etsyetsy&#xe026;
facebookfacebook&#xe027;
feedburnerfeedburner&#xe028;
flickrflickr&#xe029;
foodspottingfoodspotting&#xe030;
forrstforrst&#xe031;
foursquarefoursquare&#xe032;
friendsfeedfriendsfeed&#xe033;
friendstarfriendstar&#xe034;
gdgtgdgt&#xe035;
githubgithub&#xe036;
githubaltgithubalt&#xe037;
googlebuzzgooglebuzz&#xe038;
googleplusgoogleplus&#xe039;
googletalkgoogletalk&#xe040;
gowallapingowallapin&#xe041;
gowallagowalla&#xe042;
groovesharkgrooveshark&#xe043;
heartheart&#xe044;
hyveshyves&#xe045;
icondockicondock&#xe046;
icqicq&#xe047;
identicaidentica&#xe048;
imessageimessage&#xe049;
itunesitunes&#xe050;
lastfmlastfm&#xe051;
linkedinlinkedin&#xe052;
meetupmeetup&#xe053;
metacafemetacafe&#xe054;
mixxmixx&#xe055;
mobilememobileme&#xe056;
mrwongmrwong&#xe057;
msnmsn&#xe058;
myspacemyspace&#xe059;
newsvinenewsvine&#xe060;
paypalpaypal&#xe061;
photobucketphotobucket&#xe062;
picasapicasa&#xe063;
pinterestpinterest&#xe064;
podcastpodcast&#xe065;
posterousposterous&#xe066;
qikqik&#xe067;
quoraquora&#xe068;
redditreddit&#xe069;
retweetretweet&#xe070;
rssrss&#xe071;
scribdscribd&#xe072;
sharethissharethis&#xe073;
skypeskype&#xe074;
slashdotslashdot&#xe075;
slideshareslideshare&#xe076;
smugmugsmugmug&#xe077;
soundcloudsoundcloud&#xe078;
spotifyspotify&#xe079;
squidoosquidoo&#xe080;
stackoverflowstackoverflow&#xe081;
starstar&#xe082;
stumbleuponstumbleupon&#xe083;
technoratitechnorati&#xe084;
tumblrtumblr&#xe085;
twitterbirdtwitterbird&#xe086;
twittertwitter&#xe087;
viddlerviddler&#xe088;
vimeovimeo&#xe089;
virbvirb&#xe090;
wwwwww&#xe091;
wikipediawikipedia&#xe092;
windowswindows&#xe093;
wordpresswordpress&#xe094;
xingxing&#xe095;
yahoobuzzyahoobuzz&#xe096;
yahooyahoo&#xe097;
yelpyelp&#xe098;
youtubeyoutube&#xe099;
instagraminstagram&#xe100;


*/