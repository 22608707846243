footer {
	// background:linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) ), url(../img/home-bg/2.jpg) 0% 0% no-repeat;
	background-color:$background-dim;
	background-size:cover;
	padding-bottom:10px;
	display:block;
	padding:40px 0 20px 0;
	color:$font-light;
	border-top:7px solid $brand-secondary-accent;
	@include respond-to(desktop)
	{
		padding:20px 0;
	}
}

footer ul {
	list-style-type:none;
	margin:0;
}

footer a, footer p span a {
	color:$font-light;
}

footer p span {
	font-size:$font-m-medium;
	letter-spacing:2px;
	line-height: 30px;
	font-family:$sans-primary;
}

footer p span.spacer {
	color:$background-dark;
}

footer p, footer p span, footer li a {
	color:$font-light;
	font-family:$sans-primary;
}

footer li a:hover, footer a:hover {
	color:$font-light;
	text-decoration:underline;
}

footer li a:active, footer a:active {
	color:$font-light;
}

.footer_link.symbol {
	font-size:$font-medium;
}

.social {
	margin-top:50px;
	min-width:200px;
}

.social li {
	float:left;
	margin:5px;
}

.footer-logo {
	text-align:left;
	margin-top:25px;
	margin-bottom:25px;
}

.footer-line {
	background:transparent url('../img/line-accent.gif') 200px 50% repeat-x;
	margin:25px 0;
}

.footer-block {
	min-height:100px;
}

.copyright {
	background:transparent url('../img/line-accent.gif') top  left repeat-x;
	margin-top:15px;
	padding-top:30px;
}